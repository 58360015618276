<template>
  <div class="form-container">
    <div class="bs-flex">
      <h5>구분</h5>
      <input class="custom-input-box dis w237" disabled v-if="isEdit" v-model="selData.type"/>
      <mdb-select
          v-else
          outline
          v-model="oneOptions"
          @getValue="getSelectOneValue"
          class="search-select"/>
    </div>
    <div class="bs-flex">
      <h5>첨부파일</h5>
      <div class="custom-input-box dis" style="display: flex">
        <img src="@/assets/images/IA/paper-clip.png" alt="clip">
        <p style="margin: 0"><a :href="selData.file"> {{ selData.fileName }}</a></p>
      </div>
    </div>
    <div class="bs-flex">
      <h5>게시글 제목</h5>
      <input class="custom-input-box" disabled v-if="isEdit" v-model="selData.title"/>
      <input class="custom-input-box" v-else v-model="selData.title"/>
    </div>
    <div class="bs-flex">
      <h5>게시글 내용</h5>
      <textarea class="custom-textarea-box dis" cols="18" readonly v-if="isEdit" v-model="selData.desc"/>
      <textarea class="custom-textarea-box" cols="18" v-else v-model="selData.desc"/>
    </div>
    <hr>
    <div class="btn-flex" v-if="isEdit">
      <button class="delete-btn" @click="deleteBoard"> 삭제</button>
      <button class="edit-btn" @click="showEdit"> 수정</button>
    </div>
    <div class="btn-flex" v-else>
      <button class="btn-cancel" @click="isEdit = true">취소</button>
      <button class="complete-btn" @click="updateBoard">완료</button>
    </div>
  </div>
</template>

<script>
import {mdbSelect} from "mdbvue";
import {firebase} from "@/firebase/firebaseConfig";
import {firebaseError} from "@/lib/firebaseError";

export default {
  name: "DataDownloadForm",
  components: {
    mdbSelect,
  },
  props: {
    selData: Object,
  },
  data() {
    return {
      fbCollection: 'reference',
      isEdit: true,
      oneOptions: [
        {text: "평가지", value: "평가지"},
        {text: "평가 매뉴얼", value: "평가 매뉴얼"},
      ],
      oneOptionVal: '평가지',
      tempData : {},
    }
  },
  methods: {
    getSelectOneValue(value) {
      this.oneOptionVal = value;
    },

    deleteBoard() {
      const self = this;
      const docRef = firebase.firestore().collection(self.fbCollection);
      docRef.doc(self.selData.docId).delete().then(() => {
        alert('삭제 되었습니다.')
        self.$emit('refresh', 0)
      })
    },
    showEdit(){
      const self = this;
      self.isEdit = false
      self.tempData = JSON.parse(JSON.stringify(self.selData))
      this.compareSelect()
    },
    compareSelect() {
      const self = this
      let index = self.oneOptions.findIndex((element) => element.value === self.tempData.type);
      self.oneOptions[index].selected = true
      self.oneOptions.push()
      if (self.selData.type === '평가지'){
        self.oneOptionVal = '평가지'
      } else {
        self.oneOptionVal = '평가 매뉴얼'
      }

    },
    updateBoard() {
      const self = this;
      const data = {
        desc: self.selData.desc,
        title: self.selData.title,
        type: self.oneOptionVal,
      }
      firebase.firestore().collection(self.fbCollection)
          .doc(self.selData.docId)
          .update(data)
          .then(async () => {
            alert('수정 되었습니다.');
            self.$emit('refresh', 0)
          }).catch((err) => {
        firebaseError(err)
      });
    }
  }
}
</script>

<style scoped>
.form-container {
  width: 100%;
  border: 1px solid #E1E3E6;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 27px 18px;
}

.bs-flex {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.bs-flex h5 {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.0015em;
  color: #272833;
  margin: 0 10px 0 0;
  min-width: 150px;
}

hr {
  border-top: 2px solid rgba(0, 0, 0, .1);
}

.dis {
  background: #F8F8FA !important;
}

.btn-flex {
  display: flex;
}

.custom-textarea-box {
  width: 100%;
  min-height: 180px;
  border-radius: 4px;
  border: 1px solid #E1E3E6 !important;
}

.delete-btn {
  width: 66px;
  height: 40px;
  background: #E14F4E;
  border-radius: 4px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.0015em;
  color: #FFFFFF;
  border: 0;
  margin-left: auto;
  margin-right: 10px;
}

.edit-btn {
  width: 66px;
  height: 40px;
  background: #0069CA;
  border-radius: 4px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.0015em;
  color: #FFFFFF;
  border: 0;
}

.complete-btn {
  width: 66px;
  height: 40px;
  background: #000000;
  border-radius: 4px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.0015em;
  color: #FFFFFF;
  border: 0;
}


.dis img {
  width: 18px;
  height: 18px;
  margin-right: 5px;
}

.btn-cancel {
  width: 66px;
  margin-left: auto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.0015em;
  color: #FFFFFF;
  height: 40px;
  background: #8D8D8D;
  border-radius: 4px;
  border: 0;
  margin-right: 10px;
}
</style>
