export default [
    {
        label: 'No.',
        field: 'orderNumber',
        sort: true
    },
    {
        label: '구분',
        field: 'type',
        sort: false
    },
    {
        label: '게시글 제목',
        field: 'title',
        sort: false
    },
    {
        label: '등록 일자',
        field: 'regDate',
        sort: true
    },
    {
        label: '다운로드 수',
        field: 'download',
        sort: true
    }
];
